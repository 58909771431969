import { useEffect, useState } from 'react';
import cn from 'classnames';
import useWindowScroll from 'react-use/lib/useWindowScroll';
import useWindowSize from 'react-use/lib/useWindowSize';

import BottomHeader from './components/BottomHeader';
import ContentContainer from '@components/ContentContainer';
import CountryConfirmation from './components/CountryConfirmation';
import TopHeader from './components/TopHeader';
import styles from './Header.module.scss';

import { BIG_TABLET_WINDOW_WIDTH, TABLET_WINDOW_WIDTH } from '@constants/breakpoints';
import { DESKTOP_FLY_OFFSET, DESKTOP_SHADOW_OFFSET } from '@constants/header';
import { EMobileMenuState, MOBILE_MENU_MODAL_NAME, prepareMobileMenuParams } from '@constants/mobileMenu';
import { useAppDispatch, useAppState } from '@store/app';

type THeader = {
  className?: string;
};

const Header = ({ className = '' }: THeader): JSX.Element => {
  const { x, y } = useWindowScroll();
  const { width } = useWindowSize();

  const [isFlyHeader, setFlyHeader] = useState(false);
  const [isShowShadow, setIsShowShadow] = useState(false);

  const { activeModal, country, isNeedConfirmCountry, isSelectCountryModalVisible } = useAppState();
  const { setCountry, setCountryModalVisibility, showModal } = useAppDispatch();

  /** Флаг, выводим ли окно подвтверждения выбранной страны
   * Показываем при условии:
   *  - есть необходимость подтвердждения страны
   *  - не отображается окно выбора страны
   * */
  const isCountryConfirmationVisible =
    isNeedConfirmCountry && !isSelectCountryModalVisible && activeModal !== MOBILE_MENU_MODAL_NAME;

  useEffect(() => {
    // При рендере страницы, если в ней скролл был где-то не вверху, не отрисовывается шапка
    // чтобы поправить это, чекаем позицию скролла, если не нулевая, запускаем шапку в полёт
    if (window.pageYOffset !== 0) {
      setFlyHeader(true);
    }
  }, []);

  useEffect(() => {
    const pageYOffset = -y;

    const isDesktop = width >= BIG_TABLET_WINDOW_WIDTH;
    const isTablet = width < BIG_TABLET_WINDOW_WIDTH && width >= TABLET_WINDOW_WIDTH;
    const isMobile = width < TABLET_WINDOW_WIDTH;

    const isNeedDesktopFly = isDesktop && pageYOffset <= -DESKTOP_FLY_OFFSET;
    const isShowShadowDesktop = isDesktop && pageYOffset <= -DESKTOP_SHADOW_OFFSET;
    const isNeedFly = isNeedDesktopFly || isTablet || isMobile;

    setFlyHeader(isNeedFly);
    setIsShowShadow(isShowShadowDesktop);
  }, [x, y, isFlyHeader, width]);

  /** Подтверждение высталенной по-умолчанию страны */
  const agreeCountryHandler = () => {
    setCountry(country);
  };

  /** Выбор другой страны: открываем либо модальное окно меню мобилки, либо окно выбора языка на десктопе */
  const showCountryDropdownHandler = () => {
    if (width < BIG_TABLET_WINDOW_WIDTH) {
      const { mobileMenuName, mobileMenuPayload } = prepareMobileMenuParams({
        menuState: EMobileMenuState.COUNTRIES
      });

      showModal(mobileMenuName, mobileMenuPayload);
    } else {
      setCountryModalVisibility(true);
    }
  };

  return (
    <header className={cn(styles.component, className)}>
      <ContentContainer className={styles.content}>
        <TopHeader />

        {isCountryConfirmationVisible && (
          <CountryConfirmation
            className={styles.countryConfirmation}
            country={country}
            onAgree={agreeCountryHandler}
            onDisagree={showCountryDropdownHandler}
          />
        )}

        <div
          className={cn(
            styles.bottomHeaderContainer,
            isFlyHeader && styles.isFlyHeader,
            isShowShadow && isFlyHeader && styles.isShowShadow
          )}
        >
          <BottomHeader isFlyHeader={isFlyHeader} />
        </div>
      </ContentContainer>
    </header>
  );
};

export default Header;
