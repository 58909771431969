import { Button, Link, ButtonTheme } from '@components/NextRouteHOC';
import HeaderCart from '@components/HeaderCart';
import LogoSmallIcon from '@static/icons/logoSmall.svg';
import MenuIcon from '@static/icons/menu.svg';
import SearchIcon from '@static/icons/headerSearch.svg';
import styles from './SlideTopSection.module.scss';

import { useCartState } from '@store/cart';
import pageUrls from '@constants/pageUrls';

type TSlideTopSectionProps = {
  onShowMenu: () => void;
  onToggleHeader: () => void;
};

const SlideTopSection = ({ onShowMenu, onToggleHeader }: TSlideTopSectionProps): JSX.Element => {
  const { itemCount: cartActiveProductCount } = useCartState();

  return (
    <div className={styles.component}>
      <div className={styles.slideLeftSection}>
        <Button
          className={styles.menuButton}
          dataTestId="Header:button:openMenuButton"
          theme={ButtonTheme.TRANSPARENT}
          onClick={onShowMenu}
        >
          <MenuIcon width={24} height={20} />
        </Button>
        <Link className={styles.logoLink} to={pageUrls.home()}>
          <LogoSmallIcon />
        </Link>
        <div className={styles.logoText}>Auto parts Marketplace</div>
      </div>

      <div className={styles.slideRightSection}>
        <Button className={styles.searchButton} theme={ButtonTheme.TRANSPARENT} onClick={onToggleHeader}>
          <SearchIcon className={styles.searchIcon} height={24} width={24} />
        </Button>

        <HeaderCart buttonClassName={styles.cart} initialItemCount={cartActiveProductCount} />
      </div>
    </div>
  );
};

export default SlideTopSection;
