import cn from 'classnames';
import styles from './ButtonCounter.module.scss';

const MAX_VALUE = 99;
const MAX_VALUE_TEXT = '+99';

export type TButtonCounterProps = {
  className?: string;
  dataTestId?: string;
  value: number;
};

const ButtonCounter = ({ className = '', dataTestId, value }: TButtonCounterProps): JSX.Element => (
  <div className={cn(styles.component, className)} data-testid={dataTestId}>
    {value > MAX_VALUE ? MAX_VALUE_TEXT : value}
  </div>
);

export default ButtonCounter;
