import React, { Ref, forwardRef, ReactElement } from 'react';
import cn from 'classnames';
import useTranslation from 'next-translate/useTranslation';

import { ButtonSize, ButtonTheme, Button as NextButton } from '@components/NextRouteHOC';
import { Input } from 'emex-ui-kit/dist/Input';
import styles from './SearchBlock.module.scss';

import ClearIcon from '@static/icons/close.svg';
import SearchIcon from '@static/icons/headerSearch.svg';

import { DEFAULT_SEARCH_PART_NUMBER, DEFAULT_SEARCH_PART_NUMBER_AND_BRAND } from '@constants/header';
import { FORMAT_CHARS_DETAIL_NUM, DETAIL_NUM_MASK } from '@constants/regexp';

type TSearchBlockProps = {
  className?: string;
  isFlyHeader: boolean;
  isUserAuthorized: boolean;
  searchTerm: string;
  onChangeSearchTerm: (value: string) => void;
  onSearch: (value?: string) => void;
};

const SearchBlock = (
  { className = '', isFlyHeader, isUserAuthorized, searchTerm, onChangeSearchTerm, onSearch }: TSearchBlockProps,
  ref?: Ref<HTMLDivElement>
): ReactElement => {
  const { t } = useTranslation();

  const handlePlaceholderClick = (value: string) => () => {
    onSearch(value);
  };

  // клик по кнопке очищения текстового поля
  const clearButtonClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    onChangeSearchTerm('');
    event.stopPropagation();
  };

  // нажатие клавиши Enter в текстовом поле
  const inputKeyPressHandler = ({ key }: React.KeyboardEvent) => {
    if (key === 'Enter' && searchTerm) {
      onSearch();
    }
  };

  // нажатие кнопки "Найти"
  const searchButtonClickHandler = (): void => {
    if (searchTerm) {
      onSearch();
    }
  };

  const renderSearchPlaceholder = (): JSX.Element => (
    <div className={styles.placeholder}>
      <span className={styles.placeholderText}>{`${t(
        isUserAuthorized
          ? 'components:header.inputSearchPlaceholderForAuthorizedUser'
          : 'components:header.inputSearchPlaceholder'
      )} — `}</span>
      <NextButton
        className={styles.demoValueButton}
        theme={ButtonTheme.TRANSPARENT}
        onClick={handlePlaceholderClick(
          isUserAuthorized ? DEFAULT_SEARCH_PART_NUMBER_AND_BRAND : DEFAULT_SEARCH_PART_NUMBER
        )}
      >
        {isUserAuthorized ? DEFAULT_SEARCH_PART_NUMBER_AND_BRAND : DEFAULT_SEARCH_PART_NUMBER}
      </NextButton>
    </div>
  );

  return (
    <div ref={ref} className={cn(styles.component, className)}>
      <Input
        className={cn(styles.searchInput, isFlyHeader && styles.isFlyHeader)}
        dataTestId="MainPage:field:searchInput"
        formatChars={FORMAT_CHARS_DETAIL_NUM}
        isWithoutFocus
        mask={DETAIL_NUM_MASK}
        maskChar=""
        placeholder={renderSearchPlaceholder()}
        value={searchTerm}
        onChange={onChangeSearchTerm}
        onKeyPress={inputKeyPressHandler}
      />

      <NextButton
        className={cn(styles.searchButton, isFlyHeader && styles.isFlyHeader)}
        dataTestId="MainPage:button:startSearch"
        size={ButtonSize.BIG}
        theme={ButtonTheme.BLACK}
        onClick={searchButtonClickHandler}
      >
        {t('components:header.buttonSearch')}
        <SearchIcon className={styles.searchIcon} />
        {searchTerm && (
          <div className={styles.clearButton} onClick={clearButtonClickHandler}>
            <ClearIcon width={16} height={16} />
          </div>
        )}
      </NextButton>
    </div>
  );
};

export default forwardRef<HTMLDivElement, TSearchBlockProps>(SearchBlock);
