import cn from 'classnames';
import { useState } from 'react';

import { Button, ButtonTheme } from '@components/NextRouteHOC';
import BackArrowIcon from '@static/icons/backArrow.svg';
import SearchBlock from './components/SearchBlock';
import SlideTopSection from './components/SlideTopSection';
import styles from './MobileHeader.module.scss';

import { MOBILE_MENU_MODAL_NAME } from '@constants/mobileMenu';
import { useAppDispatch } from '@store/app';

type TMobileHeaderProps = {
  className?: string;
  searchTerm: string;
  onChangeSearchTerm: (value: string) => void;
  onSearch: () => void;
};

const MobileHeader = ({
  className = '',
  searchTerm,
  onChangeSearchTerm,
  onSearch
}: TMobileHeaderProps): JSX.Element => {
  const [isSearchShow, setSearchShow] = useState(false);
  const { showModal } = useAppDispatch();

  // хендлер показа модального меню
  const showMenuHandler = () => {
    showModal(MOBILE_MENU_MODAL_NAME);
  };

  // хендлер смены текущего слайда
  const toggleHeaderHandler = (): void => {
    setSearchShow(!isSearchShow);
  };

  return (
    <div className={cn(styles.component, className)}>
      <div className={cn(styles.swipeContainer, isSearchShow && styles.isSearchShow)}>
        <div className={styles.slide}>
          <Button className={styles.buttonBack} theme={ButtonTheme.TRANSPARENT} onClick={toggleHeaderHandler}>
            <BackArrowIcon width={24} height={21} />
          </Button>
          <SearchBlock searchTerm={searchTerm} onChangeSearchTerm={onChangeSearchTerm} onSearch={onSearch} />
        </div>

        <div className={styles.slide}>
          <SlideTopSection onShowMenu={showMenuHandler} onToggleHeader={toggleHeaderHandler} />
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
