import cn from 'classnames';
import useTranslation from 'next-translate/useTranslation';

import { Button, ButtonTheme } from '@components/NextRouteHOC';
import styles from './CountryConfirmation.module.scss';

export type TCountryConfirmationProps = {
  className?: string;
  country: string;
  onAgree: () => void;
  onDisagree: () => void;
};

const CountryConfirmation = ({ className, country, onAgree, onDisagree }: TCountryConfirmationProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.component, className)}>
      <div className={styles.heading}>
        {t('components:header.textCountryForDeliveryQuestion', { countryName: t(`common:countries.${country}`) })}
      </div>

      <div className={styles.buttonsContainer}>
        <Button dataTestId="Header:button:countryAgreeButton" theme={ButtonTheme.ORANGE} onClick={onAgree}>
          {t('components:header.textCountryForDeliveryAgree')}
        </Button>

        <Button
          className={styles.buttonChangeCountry}
          dataTestId="Header:button:countryNotAgreeButton"
          theme={ButtonTheme.WHITE}
          onClick={onDisagree}
        >
          {t('components:header.textCountryForDeliveryNotAgree')}
        </Button>
      </div>
    </div>
  );
};

export default CountryConfirmation;
