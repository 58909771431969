import cn from 'classnames';

import MasterCardIcon from '@static/icons/masterCard.svg';
import VisaIcon from '@static/icons/visa.svg';
import styles from './CardsBlock.module.scss';

const CardsBlock = ({ className }: { className?: string }): JSX.Element => (
  <div className={cn(styles.component, className)}>
    <div className={styles.iconBox}>
      <VisaIcon width={75} height={40} />
    </div>
    <div className={styles.iconBox}>
      <MasterCardIcon width={60} height={40} />
    </div>
  </div>
);

export default CardsBlock;
