/** Название модального окна меню */
export const MOBILE_MENU_MODAL_NAME = 'mobileMenu';

/** Состояния (страницы) меню модального окна */
export enum EMobileMenuState {
  COUNTRIES = 'countries',
  MAIN = 'main'
}

/** Состояние (страница) меню модального окна по-умолчанию */
export const DEFAULT_MOBILE_MENU_STATE = EMobileMenuState.MAIN;

export type TMobileMenuParams = {
  menuState?: EMobileMenuState;
};

/** Метод подготовки параметров меню модального окна */
export function prepareMobileMenuParams(mobileMenuPayload: TMobileMenuParams): {
  mobileMenuName: string;
  mobileMenuPayload: TMobileMenuParams;
} {
  return {
    mobileMenuName: MOBILE_MENU_MODAL_NAME,
    mobileMenuPayload
  };
}

/** Метод возвращает первоначальное значение стейт-шага */
export function getInitialMobileMenuState(initialMenuState?: EMobileMenuState): EMobileMenuState {
  if (initialMenuState === undefined) {
    return DEFAULT_MOBILE_MENU_STATE;
  }

  if (Object.values(EMobileMenuState).includes(initialMenuState)) {
    return initialMenuState;
  }

  return DEFAULT_MOBILE_MENU_STATE;
}
