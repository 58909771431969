import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './ContentContainer.module.scss';

type TContentContainerProps = {
  className?: string;
  children: ReactNode;
};

const ContentContainer = ({ className, children }: TContentContainerProps): JSX.Element => (
  <div className={cn(styles.component, className)}>{children}</div>
);

export default ContentContainer;
