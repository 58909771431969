import { ReactNode } from 'react';
import cn from 'classnames';
import dynamic from 'next/dynamic';

import ContentContainer from '@components/ContentContainer';
import ErrorBoundary from '@components/ErrorBoundary';
import Footer from '@components/Footer';
import Header from '@components/Header';
import TestClientErrorOnStaging from '@components/TestClientErrorOnStaging';
import styles from './MainLayout.module.scss';

const CookiePopupFeature = dynamic(() => import('@components/CookiePopupFeature'), {
  ssr: false
});

type TMainLayout = {
  className?: string;
  children: ReactNode;
  topContent?: ReactNode;
};

const MainLayout = ({ className = '', children, topContent = null }: TMainLayout): JSX.Element => (
  <div className={cn(styles.container, className)}>
    <div className={styles.headerAndContent}>
      <Header />
      {topContent}
      <ContentContainer className={styles.content}>
        <ErrorBoundary>
          <TestClientErrorOnStaging queryParamForThrowingError="testClientContentError">
            {children}
          </TestClientErrorOnStaging>
        </ErrorBoundary>
      </ContentContainer>
    </div>
    <Footer />
    <CookiePopupFeature />
  </div>
);

export default MainLayout;
