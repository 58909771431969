import { ReactNode } from 'react';
import NextHead from 'next/head';

import { SMALL_DESKTOP_WINDOW_WIDTH } from '@constants/breakpoints';

type THeadProps = {
  children?: ReactNode;
  /** Является ли страница адаптивной */
  isResponsive?: boolean;
};

const Head = ({ isResponsive = true, children }: THeadProps): JSX.Element => {
  const metaViewport = isResponsive ? (
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
  ) : (
    <>
      <meta name="viewport" content={`width=${SMALL_DESKTOP_WINDOW_WIDTH}`} />
      <style>
        {`
          body {
            min-width: 1184px !important;
          }
        `}
      </style>
    </>
  );

  return (
    <NextHead>
      {metaViewport}
      {children}
    </NextHead>
  );
};

export default Head;
