import CardsBlock from './components/CardsBlock';
import ContentContainer from '@components/ContentContainer';
import LinksBlock from './components/LinksBlock';
import styles from './Footer.module.scss';

const Footer = (): JSX.Element => (
  <div className={styles.component}>
    <ContentContainer className={styles.content}>
      <LinksBlock />
      <CardsBlock className={styles.cardsBlock} />
    </ContentContainer>
  </div>
);

export default Footer;
