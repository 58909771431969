import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';

import ButtonCounter from '@components/ButtonCounter';
import CartIcon from '@static/icons/basket.svg';
import NextLink from 'next/link';
import styles from './HeaderCart.module.scss';

import { useAuthState } from '@store/auth';
import { useCartDispatch } from '@store/cart';

import pageUrls from '@constants/pageUrls';

const MiniCart = dynamic(() => import('@components/HeaderCart/components/MiniCart'));

type THeaderCart = {
  /** Класс для элемента кнопки */
  buttonClassName?: string;
  /** Количество товаров, добавленных в корзину */
  initialItemCount: number;
  /** Нужно ли отображать текст "Корзина" */
  isLabelVisible?: boolean;
};

const HeaderCart = ({ buttonClassName, initialItemCount, isLabelVisible = false }: THeaderCart): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();

  const [itemCount, setItemCount] = useState(0);
  const { isUserAuthorized } = useAuthState();

  const { getLastAddedProduct } = useCartDispatch();
  const addedProduct = getLastAddedProduct();

  // меняем каунтер количества товаров
  useEffect(() => {
    if (initialItemCount !== itemCount) {
      setItemCount(initialItemCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialItemCount]);

  return (
    <div className={styles.component}>
      <NextLink
        href={{
          pathname: pageUrls.cart(),
          query: isUserAuthorized && router.pathname === '/cart' ? { state: 'cart' } : {}
        }}
      >
        <a className={cn(styles.link, buttonClassName)} data-test-id="Header:link:cart">
          <CartIcon data-testid="Header:tag:cartIcon" />
          {itemCount > 0 && <ButtonCounter dataTestId="Header:button:cartCounter" value={itemCount} />}
          {isLabelVisible && <div className={styles.label}>{t('components:headerCart.cart')}</div>}
        </a>
      </NextLink>

      {addedProduct && (
        <MiniCart
          makerName={addedProduct.makerName}
          price={addedProduct.offers[0].price}
          productName={addedProduct.name}
          productNumber={addedProduct.number}
        />
      )}
    </div>
  );
};

export default HeaderCart;
