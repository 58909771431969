import { useRef, useState, useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import useClickAway from 'react-use/lib/useClickAway';
import useWindowSize from 'react-use/lib/useWindowSize';

import { RemoveScroll } from 'react-remove-scroll';
import CountryButton from './components/CountryButton';
import LoginBox from './components/LoginBox';
import styles from './TopHeader.module.scss';

const CountryDropdown = dynamic(() => import('@components/CountryDropdown'));

import { TDOMEvent } from '@typesTS/domEvents';
import { useAppDispatch, useAppState } from '@store/app';
import { MODAL_SELECT_COUNTRY_KEY } from '@constants/modals';

const COUNTRY_SELECT_TOP_OFFSET_PX = 16;
const HEADER_HEIGHT = 40;

const TopHeader = (): JSX.Element => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [scrollTop, setScrollTop] = useState(0);

  const { allCountries, country, isSelectCountryModalVisible } = useAppState();
  const { setCountry, setCountryModalVisibility, toggleCountryModal } = useAppDispatch();

  const countryButtonRef = useRef<HTMLButtonElement>(null);
  const countrySelectRef = useRef<HTMLDivElement>(null);

  // При изменении ширины окна скрываем дропдаун выбора страны
  useEffect(() => {
    setCountryModalVisibility(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  // при открытии окна выбора страны обновляем переменную скролл-позиции
  useEffect(() => {
    if (isSelectCountryModalVisible) {
      setScrollTop(window.scrollY);
    }
  }, [isSelectCountryModalVisible]);

  // клик вне открытого контейнера с выбором страны закрывает данное окно
  useClickAway(countrySelectRef, (event: TDOMEvent<HTMLElement>) => {
    const isClickHappenedOnTriggerElement = Boolean(
      event.target.hasAttribute(`[data-modal-trigger=${MODAL_SELECT_COUNTRY_KEY}]`)
        ? event.target
        : event.target.closest(`[data-modal-trigger=${MODAL_SELECT_COUNTRY_KEY}]`)
    );

    if (
      countryButtonRef.current &&
      !countryButtonRef.current.contains(event.target) &&
      !isClickHappenedOnTriggerElement
    ) {
      setCountryModalVisibility(false);
    }
  });

  /** Хендлер выбора страны */
  const selectCountryHandler = (newCountry: string): void => {
    setCountry(newCountry);
    setCountryModalVisibility(false);
  };

  const top = scrollTop > HEADER_HEIGHT ? scrollTop + COUNTRY_SELECT_TOP_OFFSET_PX : 0;

  return (
    <div className={styles.component}>
      <div className={styles.content}>
        <CountryButton
          countryName={country ? t(`common:countries.${country}`) : ''}
          ref={countryButtonRef}
          onClick={toggleCountryModal}
        />
        <LoginBox />
      </div>

      {isSelectCountryModalVisible && (
        <RemoveScroll enabled>
          <div className={styles.countrySelectContainer} style={{ top: top > 0 ? `calc(100% + ${top}px)` : '100%' }}>
            <CountryDropdown
              countries={allCountries}
              wrapperRef={countrySelectRef}
              onSelectCountry={selectCountryHandler}
            />
          </div>
        </RemoveScroll>
      )}
    </div>
  );
};

export default TopHeader;
