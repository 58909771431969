import React from 'react';
import cn from 'classnames';
import useTranslation from 'next-translate/useTranslation';

import LinksList from './components/LinksList';
import styles from './LinksBlock.module.scss';

import { getCustomersContentPageList, getEmexContentPageList } from '@constants/contentPages';

const LinksBlock = ({ className }: { className?: string }): JSX.Element => {
  const { t } = useTranslation();

  const CUSTOMERS_LIST = getCustomersContentPageList(t);
  const EMEX_LIST = getEmexContentPageList(t);

  return (
    <div className={cn(styles.component, className)}>
      <div className={styles.listBlock}>
        <div className={styles.listHeading}>{t('components:footer.headingCustomers')}</div>
        <LinksList items={CUSTOMERS_LIST} />
      </div>
      <div className={styles.listBlock}>
        <div className={styles.listHeading}>{t('components:footer.headingEmex')}</div>
        <LinksList items={EMEX_LIST} />
      </div>
    </div>
  );
};

export default LinksBlock;
