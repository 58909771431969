import useTranslation from 'next-translate/useTranslation';

import { Button, ButtonTheme, Link } from '@components/NextRouteHOC';
// import FaqIcon from '@static/icons/faq.svg';
import PackageIcon from '@static/icons/package.svg';
import PersonIcon from '@static/icons/person.svg';
import SearchListIcon from '@static/icons/searchList.svg';
import UserBox from './components/UserBox';
import styles from './LoginBox.module.scss';

import { SIGN_IN_POPUP_NAME } from '@store/auth/entities/signIn';
// import { getFaqUrl } from '@constants/businessData';
import { useAppDispatch } from '@store/app';
import { useAuthState } from '@store/auth';
import pageUrls from '@constants/pageUrls';

const LoginBox = (): JSX.Element => {
  const { t } = useTranslation();

  const { showModal } = useAppDispatch();
  const { isUserAuthorized, userName } = useAuthState();

  // const faqUrl = getFaqUrl(locale);

  const loginHandler = () => {
    showModal(SIGN_IN_POPUP_NAME);
  };

  return (
    <ul className={styles.component}>
      {isUserAuthorized && (
        <li>
          <Link className={styles.link} to={pageUrls.bulkSearch()} isWithoutVisited>
            <SearchListIcon className={styles.linkIcon} />
            {t('common:links.bulkSearch')}
          </Link>
        </li>
      )}

      {/*
      <li>
        <Link className={styles.link} href={faqUrl} isWithoutVisited rel="noopener noreferrer" target="_blank">
          <FaqIcon className={styles.linkIcon} />
          {t('common:links.help')}
        </Link>
      </li>
      */}

      <li>
        <Link className={styles.link} to={pageUrls.orders()} isWithoutVisited>
          <PackageIcon className={styles.linkIcon} />
          {t('common:links.orders')}
        </Link>
      </li>

      <li>
        {isUserAuthorized ? (
          <UserBox userName={userName} />
        ) : (
          <Button className={styles.link} theme={ButtonTheme.TRANSPARENT} onClick={loginHandler}>
            <PersonIcon className={styles.linkIcon} />
            {t('common:links.login')}
          </Button>
        )}
      </li>
    </ul>
  );
};

export default LoginBox;
