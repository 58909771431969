import NProgress from 'nprogress';
import Router from 'next/router';
import searchProductService from '@services/api/products/searchProduct';
import slashToCode from '@shared/slashToCode';

export function useSearch() {
  const searchProduct = (country: string, searchNum: string): void => {
    NProgress.start();

    searchProductService({ country, searchNum })
      .then(data => {
        NProgress.done();

        let redirectUrlAs;
        let redirectUrlTo;

        // если найден конкретный товар
        if (data.product !== null) {
          redirectUrlAs = `/oem/${slashToCode(searchNum)}/${data.product?.product?.urlMakerName}/`;
          redirectUrlTo = '/oem/[searchNum]/[makeName]/';
        } else {
          redirectUrlAs = `/oem/${slashToCode(searchNum)}/`;
          redirectUrlTo = '/oem/[searchNum]/';
        }

        Router.push(redirectUrlTo, redirectUrlAs);
      })
      .catch(() => {
        NProgress.done();
      });
  };

  return { searchProduct };
}
