import request from '@services/common/request';
import { TMaker } from '@entities/makers';
import { TSearchedProduct } from '@entities/product';

type TSearchProductDto = {
  /** Страна доставки */
  country: string;
  /** Поисковый терм (партномер) */
  searchNum: string;
  /** Url-название бренда */
  urlMakerName?: string;
};

export type TSearchProductResponse = {
  /** Список найденных товаров по брендам */
  productList: { makers: TMaker[] } | null;
  /** Детали найденного товара с его аналогами и заменами */
  product: TSearchedProduct | null;
};

/** Метод поиска товаров.
 *  Возвращает либо детали товара, либо список найденных товаров по брендам, либо отсутствие результатов поиска
 * */
export default async function searchProduct({
  country,
  searchNum,
  urlMakerName
}: TSearchProductDto): Promise<TSearchProductResponse> {
  return request
    .get('/api/search', {
      country,
      searchNum,
      urlMakerName
    })
    .then(res => res.data);
}
