import { LinkTheme } from 'emex-ui-kit/dist/Link';
import { Translate } from 'next-translate';
import pageUrls from '@constants/pageUrls';

export type TContentPageList = {
  content: number | string;
  href: string;
  theme?: LinkTheme;
}[];

/* Получение ссылок списка "Покупатели" */
export const getCustomersContentPageList = (t: Translate): TContentPageList => {
  return [
    {
      href: pageUrls.aboutPayments(),
      theme: LinkTheme.DEFAULT,
      // TODO: удалить String при переходе на next-translate, так как не нужно будет
      content: String(t('common:links.payment'))
    },
    {
      href: pageUrls.aboutReturns(),
      theme: LinkTheme.DEFAULT,
      // TODO: удалить String при переходе на next-translate, так как не нужно будет
      content: String(t('common:links.return'))
    },
    {
      href: pageUrls.aboutDelivery(),
      theme: LinkTheme.DEFAULT,
      // TODO: удалить String при переходе на next-translate, так как не нужно будет
      content: String(t('common:links.delivery'))
    }
  ];
};

/* Получение ссылок списка "Emex" */
export const getEmexContentPageList = (t: Translate): TContentPageList => {
  return [
    {
      href: pageUrls.about(),
      theme: LinkTheme.DEFAULT,
      // TODO: удалить String при переходе на next-translate, так как не нужно будет
      content: String(t('common:links.company')) || ''
    },
    {
      href: pageUrls.aboutContacts(),
      theme: LinkTheme.DEFAULT,
      // TODO: удалить String при переходе на next-translate, так как не нужно будет
      content: String(t('common:links.contacts')) || ''
    }
  ];
};
