/** Паттерн ввода email */
export const EMAIL_REGEXP =
  /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/m;

/** Паттерн ввода телефона */
export const PHONE_SYMBOLS_REGEXP = /^[0-9()\-+ ]+$/;

// TODO: переименовать и написать комментарии
export const DETAIL_NUM_MASK = '**************************************************';

export const FORMAT_CHARS_DETAIL_NUM = {
  '*': '[0-9A-Za-zА-Яа-яёЁ\\- ]'
};
