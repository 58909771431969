import { useEffect, useState } from 'react';
import Router from 'next/router';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import useWindowSize from 'react-use/lib/useWindowSize';

import { Button, ButtonSize, ButtonTheme, Link } from '@components/NextRouteHOC';
import LogoIcon from '@static/icons/logo.svg';
import LogoSmallIcon from '@static/icons/logoSmall.svg';
import MenuIcon from '@static/icons/menu.svg';
import MobileHeader from './components/MobileHeader';
import SearchBlock from './components/SearchBlock';
import styles from './BottomHeader.module.scss';

import { BIG_TABLET_WINDOW_WIDTH, TABLET_WINDOW_WIDTH } from '@constants/breakpoints';
import { MOBILE_MENU_MODAL_NAME } from '@constants/mobileMenu';
import { useAppDispatch, useAppState } from '@store/app';
import { useAuthState } from '@store/auth';
import { useCartState } from '@store/cart';
import { useSearch } from '@store/search/useSearch';
import pageUrls from '@constants/pageUrls';
import slashToCode from '@shared/slashToCode';

const HeaderCart = dynamic(() => import('@components/HeaderCart'));
const MobileMenuModal = dynamic(() => import('./components/MobileMenuModal'));

const BottomHeader = ({ isFlyHeader }: { isFlyHeader: boolean }): JSX.Element => {
  const { width } = useWindowSize();
  const { activeModal, allCountries, country } = useAppState();
  const { hideModal, setCountry, showModal } = useAppDispatch();
  const { isUserAuthorized } = useAuthState();
  const [searchTerm, setSearchTerm] = useState('');

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const { itemCount: cartActiveProductCount } = useCartState();
  const { searchProduct } = useSearch();

  useEffect(() => {
    const isDesktopVersion = width >= BIG_TABLET_WINDOW_WIDTH;
    const isMobileVersion = width < TABLET_WINDOW_WIDTH;

    if (isDesktopVersion !== isDesktop) {
      setIsDesktop(isDesktopVersion);
    }

    if (isMobileVersion !== isMobile) {
      setIsMobile(isMobileVersion);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const isMobileMenuVisible = width < BIG_TABLET_WINDOW_WIDTH && activeModal === MOBILE_MENU_MODAL_NAME;

  /** Открытие/закрытие мобильного окна настроек */
  const changeMenuVisibilityHandler = (): void => {
    if (!isMobileMenuVisible) {
      showModal(MOBILE_MENU_MODAL_NAME);
    } else {
      hideModal();
    }
  };

  /** Изменение поискового терма */
  const changeSearchTermHandler = (value: string): void => {
    setSearchTerm(value);
  };

  /** Запуск поиска */
  const searchHandler = (value?: string): void => {
    if (value) {
      setSearchTerm(value);
    }

    const searchNum = value || searchTerm;

    if (searchNum) {
      if (isUserAuthorized) {
        const matchResult = searchNum.match(/^(\S+)\s(.+)/);

        if (matchResult) {
          const [, productNumber, makeName] = matchResult;

          if (productNumber && makeName) {
            Router.push(
              '/oem/[productNumber]/[makeName]/',
              `/oem/${slashToCode(productNumber)}/${encodeURIComponent(makeName.toUpperCase())}/`
            );
          }
        }
      } else {
        searchProduct(country, value || searchTerm);
      }
    }
  };

  return (
    <>
      {isMobile ? (
        <MobileHeader searchTerm={searchTerm} onChangeSearchTerm={changeSearchTermHandler} onSearch={searchHandler} />
      ) : (
        <div className={cn(styles.component, isFlyHeader && styles.component__fly)}>
          <Button
            className={cn(styles.menuButton, isMobileMenuVisible && styles.showMenuModal)}
            dataTestId="Header:button:openMenuButton"
            size={ButtonSize.BIG}
            theme={ButtonTheme.TRANSPARENT}
            onClick={changeMenuVisibilityHandler}
          >
            <MenuIcon width={24} height={20} />
          </Button>

          <div className={styles.logo}>
            <Link className={styles.logoLink} dataTestId="Header:link:mainLogo" to={pageUrls.home()}>
              <LogoIcon className={styles.logoIcon} />
              <LogoSmallIcon className={styles.logoSmallIcon} />
            </Link>
            <div className={styles.logoText}>Auto parts Marketplace</div>
          </div>

          <div className={styles.searchContainer}>
            <SearchBlock
              isFlyHeader={isFlyHeader}
              isUserAuthorized={isUserAuthorized}
              searchTerm={searchTerm}
              onChangeSearchTerm={changeSearchTermHandler}
              onSearch={searchHandler}
            />
          </div>

          <HeaderCart
            buttonClassName={styles.cart}
            initialItemCount={isUserAuthorized ? 0 : cartActiveProductCount}
            isLabelVisible={isDesktop}
          />
        </div>
      )}

      {!isDesktop && isMobileMenuVisible && (
        <MobileMenuModal
          countries={allCountries}
          country={country}
          onClose={changeMenuVisibilityHandler}
          onSelectCountry={setCountry}
        />
      )}
    </>
  );
};

export default BottomHeader;
