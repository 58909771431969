import React from 'react';

import { Link, LinkTheme } from '@components/NextRouteHOC';
import styles from './LinksList.module.scss';

import { TContentPageList } from '@constants/contentPages';

const LinksList = ({ items }: { items: TContentPageList }): JSX.Element => (
  <ul className={styles.component}>
    {items.map(item => (
      <li key={item.href}>
        <Link isWithoutVisited theme={item.theme || LinkTheme.DEFAULT} to={item.href}>
          {item.content}
        </Link>
      </li>
    ))}
  </ul>
);

export default LinksList;
