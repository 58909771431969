import { useCallback, useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import useClickAway from 'react-use/lib/useClickAway';

import { Button, ButtonTheme } from '@components/NextRouteHOC';
import ExitIcon from '@static/icons/exit.svg';
import PersonIcon from '@static/icons/person.svg';
import styles from './UserBox.module.scss';

import { TDOMEvent } from '@typesTS/domEvents';
import { useAuthDispatch } from '@store/auth';

type TUserBoxProps = {
  userName: string;
};

const UserBox = ({ userName }: TUserBoxProps): JSX.Element => {
  const { t } = useTranslation();

  const [isOpened, setIsOpened] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);

  const { logout } = useAuthDispatch();

  // Клик вне открытого меню пользователя
  useClickAway(componentRef, (event: TDOMEvent<HTMLElement>) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setIsOpened(false);
    }
  });

  /** Хендлер открытия/закрытия меню */
  const triggerClickHandler = useCallback((): void => {
    setIsOpened(isPrevOpened => !isPrevOpened);
  }, []);

  const clickLogoutHandler = (): void => {
    setIsOpened(false);
    logout();
  };

  return (
    <div className={styles.component} ref={componentRef}>
      <Button className={styles.userButton} theme={ButtonTheme.TRANSPARENT} onClick={triggerClickHandler}>
        <PersonIcon className={styles.userIcon} />
        {userName}
      </Button>

      {isOpened && (
        <ul className={styles.menu}>
          <li>
            <Button className={styles.menuButton} theme={ButtonTheme.TRANSPARENT} onClick={clickLogoutHandler}>
              <ExitIcon className={styles.menuItemIcon} />
              {t('common:links.logout')}
            </Button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default UserBox;
