import { forwardRef, ReactElement, Ref } from 'react';

import { Button, ButtonTheme } from '@components/NextRouteHOC';
import styles from './CountryButton.module.scss';

import ArrowDownIcon from '@static/icons/arrowDownSmall.svg';

type TCountryButtonProps = {
  countryName: string;
  onClick: () => void;
};

const CountryButton = ({ countryName, onClick }: TCountryButtonProps, ref: Ref<HTMLButtonElement>): ReactElement => (
  <Button
    className={styles.component}
    dataTestId="Header:button:changeCountry"
    ref={ref}
    theme={ButtonTheme.TRANSPARENT}
    onClick={onClick}
  >
    {countryName}
    <ArrowDownIcon className={styles.arrowIcon} width={11} height={8} />
  </Button>
);

export default forwardRef<HTMLButtonElement, TCountryButtonProps>(CountryButton);
