import { type KeyboardEvent } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Button, ButtonTheme } from '@components/NextRouteHOC';
import { Input } from 'emex-ui-kit/dist/Input';
import ClearInputIcon from '@static/icons/close.svg';
import SearchIcon from '@static/icons/headerSearch.svg';
import styles from './SearchBlock.module.scss';

import { FORMAT_CHARS_DETAIL_NUM, DETAIL_NUM_MASK } from '@constants/regexp';
import { useAuthState } from '@store/auth';

type TSearchBlockProps = {
  searchTerm: string;
  onChangeSearchTerm: (value: string) => void;
  onSearch: () => void;
};

const SearchBlock = ({ searchTerm, onChangeSearchTerm, onSearch }: TSearchBlockProps): JSX.Element => {
  const { t } = useTranslation();
  const { isUserAuthorized } = useAuthState();

  // клик по кнопке очищения текстового поля
  const clearButtonClickHandler = (): void => {
    onChangeSearchTerm('');
  };

  // нажатие клавиши Enter в текстовом поле
  const inputKeyPressHandler = ({ key }: KeyboardEvent) => {
    if (key === 'Enter' && searchTerm) {
      onSearch();
    }
  };

  // нажатие кнопки "Найти"
  const searchButtonClickHandler = (): void => {
    if (searchTerm) {
      onSearch();
    }
  };

  const placeholderContent = (
    <span className={styles.placeholder}>
      {isUserAuthorized
        ? t('components:header.inputSearchPlaceholderForAuthorizedUserShort')
        : t('components:header.inputSearchPlaceholderShort')}
    </span>
  );

  return (
    <>
      <Input
        className={styles.input}
        value={searchTerm}
        isWithoutFocus
        mask={DETAIL_NUM_MASK}
        formatChars={FORMAT_CHARS_DETAIL_NUM}
        placeholder={placeholderContent}
        onChange={onChangeSearchTerm}
        onKeyPress={inputKeyPressHandler}
      />

      {searchTerm && (
        <Button className={styles.clearButton} theme={ButtonTheme.TRANSPARENT} onClick={clearButtonClickHandler}>
          <ClearInputIcon width={16} height={16} />
        </Button>
      )}

      <Button className={styles.searchButton} theme={ButtonTheme.BLACK} onClick={searchButtonClickHandler}>
        <SearchIcon width={24} height={24} />
      </Button>
    </>
  );
};

export default SearchBlock;
